.about {

  &__title {
    color: #4b6e9b;
    font-weight: bold;
  }

  &__name {
    color: #4b6e9b;
    font-weight: bold;
  }

}
