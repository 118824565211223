.backgroundVideo { 

  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(4 3 3 / 30%);
    z-index: 1;

    &__contentWrap {
      position: absolute;
      top: 30%;
      z-index: 3;
      left: 10%;
      color: white;
      max-width: 700px;

      &__title {
        font-weight: bold;
        font-size: 40px;
        margin-bottom: 20px;

      }
      &__subTitle {
        font-size: 22px;

       }
    } 
  }
  &__swipe {
    position: absolute;
    z-index: 3;
    bottom: 20%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 900px) {
  .backgroundVideo {
 
   &__bg {
 
     &__contentWrap {

       &__title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;

      }
      &__subTitle {
        width: 300px;
        font-size: 12px;

       }
     }
   }
  //  &__swipe {
  //   position: absolute;
  //   z-index: 3;
  //   bottom: 20%;
  //   left: 50%;
  //   right: 50%;
  //   transform: translate(-50%, -50%);
  // }
  } 
  
 }
