.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;

  background-color: white;

  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
  rgba(17, 17, 26, 0.1) 0px 8px 24px,
   rgba(17, 17, 26, 0.1) 0px 16px 56px;
  
  &__wrap {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    justify-content: space-around;
   
  }

  &__list {
    max-width: 600px;
    width: 100%;

    padding: 0;

    list-style: none;
    color: black;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__item {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      &::after {
        background-color: black;
        display: block;
        content: "";
        height: 2px;
        width: 0%;
        transition: width 0.3s ease-in-out;
        margin-top: 2px;
      }
      &:hover::after,
      &:focus:after {
        width: 100%;
      }
    }
  }

  &__burger {
    margin: auto 0;
  }
}

@media (max-width: 1200px) {
  .header {

    &__wrap{
      
      max-width: 900px;
    }
  }
  
}

@media (max-width: 900px) {
 .header {
  &__wrap {
    justify-content: space-between;
    padding: 0px 10px;

    & img {
      width: 50px !important;  
    }
  }
  &__list {
    display: none;
  }
 } 
}
