.accordion {
  
  &__action {
    animation: accordionClose 0.8s ease-out forwards;


    &__open {
      animation: accordionOpen 1.3s ease-out forwards;
    }
  }
  
}

@keyframes accordionOpen {
  0% {
    max-height: 0;
    opacity: 0;
  }
  50%{
    max-height: 500px;
    opacity: 0.5;
  }
  100% {
    max-height: 1000px; /* Высота контента, которую вы хотите показать */
    opacity: 1;
  }
}

@keyframes accordionClose {
  100% {
    max-height: 0px; /* Высота контента, которую вы хотите показать */
    opacity: 0;   
  }
  50%{
    max-height: 0px;
    opacity: 0;
  }
  0% {
    max-height: 0;
    opacity: 0;
  }
}

.accordion__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border: 2px solid #4b6e9b;
}

.accordion__content {
  padding: 10px;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
  overflow: hidden;
}

.arrow__up {
  transform: rotate(180deg);
  transition: transform 0.3s ease-out;

}

.arrow__down {
  transform: rotate(0deg);
  transition: transform 0.3s ease-out;

}

.accordion__content {
background-color: #eee;
border: 2px solid #4b6e9b;
}



@media (max-width: 900px) {
  .accordion__header {
    
  }
  
  .accordion__content {
   
  }
  
  .arrow__up {
  
  }
  
  .arrow__down {
    
  }
  
  .accordion__content {

  }
 }
