.faq {
  &__wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;

    &__title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &__subTitle {
      margin-bottom: 10px;
    }
    &__acc {
      max-width: 900px;
    }
  }
}

@media (max-width: 900px) {
  .faq {
    &__wrapper {
      padding: 10px;
  
      &__title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      &__subTitle {
        margin-bottom: 10px;
        font-size: 12px;
      }
      
    }
  }

  
}
