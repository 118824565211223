.steps {
  background-image: url('https://resolute-logistics.com/optimized/uploads/main_page/how_we_work_bg_1366@2x.webp');
  margin-bottom: 50px;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    max-width: 1100px;
    margin: 0 auto;
    align-items: center;
    color: white;

    &__title {
      width: 100%;
      font-size: 40px;
      text-align: center;
      margin-bottom: 40px;
      padding-top: 20px;
    }


    &__block {
      width: 300px;
      height: 300px;
      margin: 15px;

      &__count {
        color: #4d6e9c;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      &__count {
        margin-bottom: 10px;
      }
      
      &__title {
        margin-bottom: 10px;
        font-size: 18px;
      }

      &__text {
        max-width: 255px;
        font-size: 14px;
      }
    }
  }
}


@media (max-width: 900px) {
  .steps {
    margin-bottom: 50px;
  
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      max-width: 1100px;
      margin: 0 auto;
      align-items: center;
      color: white;
      justify-content: center;
  
      &__title {
        width: 100%;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
        padding-top: 40px;
      }
  
      &__block {
        width: 300px;
        height: 250px;
        margin: 15px;
  
        &__count {
          color: #4d6e9c;
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 20px;
        }
  
        &__count {
          margin-bottom: 10px;
        }
        
        &__title {
          margin-bottom: 10px;
          font-size: 18px;
        }
  
        &__text {
          max-width: 255px;
          font-size: 14px;
        }
      }
    }
  }
  
 }
