@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./assets/uicons-regular-rounded/css/uicons-regular-rounded.css";
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
