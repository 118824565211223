.footer {
  padding: 50px 0;
  background-color: black;
  &__wrap {
  
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  &__logo {
    background: white;
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }

  &__title {
    color: white;

    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 40px;

    & span {
      border-bottom: 3px solid rgba(90, 150, 182, 1);
    }
  }
  &__list {
    display: flex;
    justify-content: space-around;

    list-style: none;

    margin: 0 auto;
    padding: 0;

    max-width: 1154px;
    width: 100%;

    &__item {
      color: white;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      &::after {
        background-color: white;
        display: block;
        content: "";
        height: 2px;
        width: 0%;
        transition: width 0.3s ease-in-out;
        margin-top: 2px;
      }
      &:hover::after,
      &:focus:after {
        width: 100%;
      }
    }
  }
}
