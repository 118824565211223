.prices {
  height: 50vh;
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    &__bg__left {
      position: absolute;
      opacity: 0.03;
      width: 700;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 25%;

      & img {
        width: 650px;
      }
    }

    &__bg__right {
      position: absolute;
      opacity: 0.03;
      width: 700;
      top: 50%;
      transform: translate(-50%, -50%);
      right: 0%;

      & img {
        width: 650px;
      }
    }

    &__title {
      font-size: 40px;
      margin: 30px 0px 40px 0px;
      
    }

  }
    &__content {
      position: relative;
      max-width: 380px;
      background-color: #4e6e9d;

      border: 1px solid #4e6e9d;
      &__title {
        text-align: center;
        font-size: 24px;
        padding: 24px;
        font-weight: 500;

      }
      &__list {
        padding: 40px 15px 50px;
        background-color: white;

        &__item {
          list-style: disc;
          margin-left: 20px;

          &__first {
            margin-bottom: 15px;
            font-size: 15px;
          }
        }
      }
    }

}

@media (max-width: 900px) {
  .prices {
    height: auto;
    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
  
      &__bg__left {
        position: absolute;
        opacity: 0.03;
        width: 700;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 25%;
  
        & img {
          width: 650px;
        }
      }
  
      &__bg__right {
        position: absolute;
        opacity: 0.03;
        width: 700;
        top: 50%;
        transform: translate(-50%, -50%);
        right: 0%;
  
        & img {
          width: 650px;
        }
      }
  
      &__title {
        font-size: 18px;
        margin: 10px 0px 10px 0px;
        
      }
  
    }
      &__content {
        position: relative;
        max-width: 380px;
        background-color: #4e6e9d;
  
        border: 1px solid #4e6e9d;
        &__title {
          text-align: center;
          font-size: 24px;
          padding: 24px;
          font-weight: 500;
  
        }
        &__list {
          padding: 40px 15px 50px;
          background-color: white;
  
          &__item {
            list-style: disc;
            margin-left: 20px;
  
            &__first {
              margin-bottom: 15px;
              font-size: 15px;
            }
          }
        }
      }
  
  }
  
 }
