
.service {
  position: relative;
  background-repeat:  no-repeat;
  background-size: cover;
  background-position: center;
  // margin-bottom: 50px;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }


  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50vh;
    color: white;
  }

  &__leftSide {
    max-width: 500px;

    &__title {
      margin-bottom: 40px;
      font-size: 40px;
    }

    &__text {
      font-size: 20px;
    }
  }
  &__rightSide {
    max-width: 500px;

    &__list {
      list-style: none;
    }
    &__item {
      margin-bottom: 10px;
      font-size: 35px;

      & span {
        color: #4d6e9c;
        font-weight: bold;
        margin-right: 20px;
      }
    }
  }

}

@media (max-width: 900px) {
  .service {
    position: relative;
    background-repeat:  no-repeat;
    background-size: cover;
    background-position: center;
    // margin-bottom: 50px;  
    &__wrapper {
     
      align-items: start;
      padding: 10px;
      flex-direction: column;
    }
  
    &__leftSide {
      max-width: 500px;
  
      &__title {
        margin-bottom: 10px;
        font-size: 18px;
      }
  
      &__text {
        font-size: 12px;
      }
    }
    &__rightSide {
      max-width: 500px;
  
      &__list {
        list-style: none;
      }
      &__item {
        margin-bottom: 10px;
        font-size: 15px;
  
        & span {
          color: #4d6e9c;
          font-weight: bold;
          margin-right: 20px;
        }
      }
    }
  
  }
 }
